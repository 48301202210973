import { Component, OnInit } from '@angular/core';

import { HomeService } from '../../pages/home.service';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  enquiryform: FormGroup;
  constructor(public service: HomeService ,private formBuilder: FormBuilder,) { }

  name: any;
  number: any;
  msg: any;
  ngOnInit(): void {
    this.enquiryform = this.formBuilder.group({

      name: ['', [Validators.required]],
      number: ['', [Validators.required]],
      msg: ['', [Validators.required]],
    });
  }


  enquirysubmit() {

    var data = this.enquiryform.value;
    console.log(data);
    
    this.service.postenquiry(data).subscribe(res => {
      // console.log(res);
      location.reload();

    });
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }



}

