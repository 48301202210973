import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './components/layouts/header/header.component';


const routes: Routes = [
  { path: '', component: HeaderComponent }
    // { path: '', component: HomeTwoComponent },
    // { path: 'error', component: ErrorComponent },
  //  { path: 'home-two', component: HomeTwoComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }