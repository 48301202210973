<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-clock-time"></i> Mon - Sun : 06:00AM - 09:00PM</li>
                        <li><i class="icofont-location-pin"></i> CTRI Rd, Bhaskar Nagar, Rajamahendravaram</li>
                        <li><i class="icofont-phone"></i> <a href="tel:+ 9196529 33339"> 096529 33339</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <div class="header-social text-end">
                    <ul>
                        <li><a target="_blank" href="#"><i class="icofont-facebook"></i></a></li>
                        <li><a target="_blank" href="https://www.sriakshara.in/admin/"><img style="width: 20px;"
                                    src="assets/img/user.png"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="#home" class="logo">
            <img src="assets/img/logo.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="#home">
                    <img style="width:30%;" src="assets/img/logo.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">

                    <ul class="navbar-nav ms-auto">

                        <li class="nav-item">
                            <!-- <a (click)="scroll(customer)">Home</a> -->
                            <a (click)="scroll(home)" class="nav-link"> Home</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a (click)="scroll(customer)">Home</a> -->
                            <a (click)="scroll(about)" class="nav-link"> About</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a (click)="scroll(customer)">Home</a> -->
                            <a (click)="scroll(services)" class="nav-link"> Services</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a (click)="scroll(customer)">Home</a> -->
                            <a (click)="scroll(features)" class="nav-link">Features </a>
                        </li>
                        <li class="nav-item">
                            <!-- <a (click)="scroll(customer)">Home</a> -->
                            <a (click)="scroll(Gallery)" class="nav-link">Gallery </a>
                        </li>


                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>



<div class="home-style-two home-bg">

    <div #home class="container" id="home">
        <div class="home-text-two">
            <h1>Keep Your Car Clean</h1>
            <p>Sri Akshara Car Care Wash and Wax deep cleans dirt, grime, stubborn stains and gives a completely clean
                and smooth surface.</p>
            <!-- <form>
                <div class="request-call">
                    <input class="form-control" placeholder="Your Number">
                    <button type="submit">Request Call</button>
                </div>
            </form> -->
        </div>

        <div class="home-image">
            <img src="assets/img/7.png" style="width:496px ;" alt="home image">
            <!-- <img src="assets/img/8.png" alt="home-image"> -->
        </div>

        <div class="home-shape">
            <img src="assets/img/service/shapes/1.png" alt="shape">
            <img src="assets/img/service/shapes/2.png" alt="shape">
            <img src="assets/img/service/shapes/3.png" alt="shape">
            <img src="assets/img/service/shapes/4.png" alt="shape">
            <img src="assets/img/service/shapes/5.png" alt="shape">
            <img src="assets/img/service/shapes/6.png" alt="shape">
            <img src="assets/img/service/shapes/7.png" alt="shape">
            <img src="assets/img/service/shapes/8.png" alt="shape">
            <img src="assets/img/service/shapes/9.png" alt="shape">
            <img src="assets/img/service/shapes/10.png" alt="shape">
        </div>
    </div>
</div>

<div #about class="info-section">
    <div class="container">
        <div class="info-area">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="info-text">
                        <i class="icofont-wall-clock"></i>
                        <h6>We Are Available</h6>
                        <p>Mon - Sun : 06:00AM - 09:00PM</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="info-text">
                        <i class="icofont-location-pin"></i>
                        <h6>Location</h6>
                        <p> CTRI Road , Manna Church
                            Back Side, Subrahmanyam nagar , Rajamahendravaram</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="info-text">
                        <i class="icofont-phone"></i>
                        <h6>Call Emergency</h6>
                        <p>096529 33339</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="info-text">
                        <i class="icofont-ui-message"></i>
                        <h6>Message Us</h6>
                        <p>srikanth.d2803@gmail.com</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #services class="service-style-two pt-100" id="Services">
    <div class="container">
        <div class="section-head text-center">
            <h2>Car Washing <span>Services</span></h2>
            <p> Sri Akshara Car Wash in Central Jail Road-Rajahmundry HO, Rajahmundry.</p>
        </div>

        <div class="service-slider-wrapper owl-carousel owl-theme">
            <div class="row align-items-center bg-white">
                <div class="col-lg-6">
                    <div class="service-img">
                        <img src="assets/img/service/1.png" alt="service image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="service-text-two">
                        <h4>Car Washing Services</h4>
                        <p>Sri Akshara Car Wash in Rajahmundry is one of the leading businesses in the Car Washing
                            Services. Also known for Car Washing Services.</p>

                        <ul>
                            <li><i class="icofont-check-circled"></i>Car Cleaning Services</li>
                            <li><i class="icofont-check-circled"></i> Steam Car Washing Services</li>
                            <li><i class="icofont-check-circled"></i> Car Glass Cleaner Dealers</li>
                            <li><i class="icofont-check-circled"></i> Car Washing Lift Manufacturers</li>
                            <li><i class="icofont-check-circled"></i> Automatic Car Washing Services</li>
                            <li><i class="icofont-check-circled"></i> Car Perfume Dealers-Ambipure</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="row align-items-center bg-white">
                <div class="col-lg-6">
                    <div class="service-img">
                        <img src="assets/img/service/3.png" alt="service image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="service-text-two">
                        <h4>Bike Washing Services</h4>
                        <p>Sri Akshara Car Wash in Rajahmundry is one of the leading businesses in the Car Washing
                            Services. Also known for Car Washing Services.</p>

                        <ul>
                            <li><i class="icofont-check-circled"></i>Full Wash (Lift wash)</li>
                            <li><i class="icofont-check-circled"></i> Body Wash</li>
                            <li><i class="icofont-check-circled"></i> Interior Cleaning</li>
                            <li><i class="icofont-check-circled"></i> Teflon Coating</li>
                            <li><i class="icofont-check-circled"></i> Polish</li>
                            <li><i class="icofont-check-circled"></i>Anti Rust Paint ( Under Body Paint)</li>
                            <li><i class="icofont-check-circled"></i>Full Detalling Work </li>
                            <li><i class="icofont-check-circled"></i> Glass Treatment </li>
                            <li><i class="icofont-check-circled"></i> Others </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="hire-section" id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="hire-text ptb-100">
                    <div class="section-head blue-title">
                        <h2>Why Should <span>Hire Us</span></h2>
                        <p>Sri Akshara Car Wash in Rajahmundry is one of the leading businesses in the Car Washing
                            Services. Also known for Car Washing Services, Car Cleaning Services, Steam Car Washing
                            Services, Car Glass Cleaner Dealers, Car Washing Lift Manufacturers, Car Perfume Dealers,
                            Automatic Car Washing Services, Waterless Car Washing Services and much more.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-6 p-0 mr-20">
                            <ul>
                                <li><i class="icofont-check-circled"></i> 100% Secured</li>
                                <li><i class="icofont-check-circled"></i> Well Disciplined</li>
                                <li><i class="icofont-check-circled"></i> Budget Oriented</li>
                                <li><i class="icofont-check-circled"></i> Eco Friendly</li>
                                <li><i class="icofont-check-circled"></i> Top Rated</li>
                            </ul>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                            <ul>
                                <li><i class="icofont-check-circled"></i> Fast & Clean Service</li>
                                <li><i class="icofont-check-circled"></i> 24/7 Services</li>
                                <li><i class="icofont-check-circled"></i> Online Payment</li>
                                <li><i class="icofont-check-circled"></i> 100% Satisfaction</li>
                                <li><i class="icofont-check-circled"></i> Clean Record</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pr-0">
                <div class="hire-img"></div>
            </div>
        </div>
    </div>
</section>

<div #features class="process-section process-style-two pb-70" id="features">
    <div class="container">
        <div class="section-head blue-title text-center">
            <h2>How Do We <span>Work</span></h2>
            <p>Sri Akshara Car Wash in Central Jail Road-Rajahmundry HO has a wide range of products and / or services
                to cater to the varied requirements of their customers.</p>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Car Washing Services</h3>
                    <p>Car cleaning services in Rajahmundry offer myriad services, right from full car spa to express
                        car washing. Depending on the type of the car</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div>

            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Automatic Car Washing Services</h3>
                    <p>Get contact details & address of companies manufacturing and supplying Automatic Car Washing
                        Machine, Automatic Car Wash Machine, Automatic Car Washer</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div>

            <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>Waterless Car Washing Services</h3>
                    <p>A 100% waterless & eco friendly car wash company. A waterless car wash uses high lubricity sprays
                        to polish and wash the vehicle's bodywork.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-style-two pt-100 pb-70">
    <div class="container">
        <h2 class="text-center">Our Track <span>Records</span></h2>

        <div class="row">
            <div class="col-md-3 col-5">
                <div class="counter-text">
                    <h2><span class="counter">2</span>K</h2>
                    <p>Job Done</p>
                </div>
            </div>

            <div class="col-md-3 col-7">
                <div class="counter-text">
                    <h2><span class="counter">3200</span>+</h2>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-md-3 col-5">
                <div class="counter-text">
                    <h2><span class="counter">09</span>+</h2>
                    <p>Experts</p>
                </div>
            </div>

            <div class="col-md-3 col-7">
                <div class="counter-text">
                    <h2><span class="counter">09</span>+</h2>
                    <p>Years of Experience</p>
                </div>
            </div>
        </div>

        <div class="counter-shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/2.png" alt="shape">
            <img src="assets/img/counter/3.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/5.png" alt="shape">
            <img src="assets/img/counter/6.png" alt="shape">
            <img src="assets/img/counter/7.png" alt="shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/8.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
        </div>
    </div>
</div>

<section #Gallery class="recent-work-section pt-100" id="recent">
    <div class="container-fluid p-0">
        <div class="section-head blue-title text-center">
            <h2>Recently <span>We Worked</span> With</h2>
        </div>

        <div class="recent-work-slider owl-carousel owl-theme">
            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/1.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/1.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/2.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/2.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/3.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/3.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/4.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/4.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/5.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/5.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/1.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/1.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/2.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/2.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/3.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/3.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/4.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/4.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/5.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/5.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/1.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/1.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/2.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/2.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/3.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/3.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/4.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/4.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/recent-work/5.png" alt="project image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/5.png" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="testimonial-section testimonial-bg ptb-100" id="reviews">
    <div class="testimonial-area">
        <div class="container">
            <div class="testimonial-slider owl-carousel owl-theme text-center">
                <div class="sin-testiText">
                    <h2>Chintha Aneel Kumar</h2>
                    <p>They scrubbed the parts of my car that wouldn’t get hit by the machine and gave me a free mirror
                        hanging air freshener.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Rajiv Nani</h2>
                    <p>Pleasantly surprised by this car wash. I also love that the vacuums are free and are pretty
                        powerful.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Pawan</h2>
                    <p>Love them! Best carwash around by far. Leaves my car shiny every time!.</p>
                </div>
            </div>
        </div>
    </div>
</div>