import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) {

  }

  postenquiry(data) {
    return this.http.post<any>(`https://sriakshara.in:9988/nodeapp/postenquiry`, data).pipe(map(res => {
    }));
  }
}


